@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: 'Inter';
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/font/Inter-Regular.ttf) format("truetype");
}

.submit-button {
  background-color: #1A73E9;
  width: 40%;
  height: 40px;
  border-radius: 4px;
  border-color: white;
  color: #fff !important;
  border: none;
  cursor: pointer;
}

.submit-button:disabled {
  opacity: 0.5;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #828282;
}

.mat-paginator-container {
  .mat-paginator-range-label {
    display: none !important;
  }
}

.mat-paginator {
  background: none !important;
}

.mat-menu-content:not(:empty) {
  margin: 20px;
}

.mat-menu-panel {
  background: #fff;
  overflow: none;
  width: 252px;
}

.mat-snack-bar-container {
  &.success {
    background-color: #4AB543;
    color: #fff;
  }
}

.mat-snack-bar-container {
  &.error {
    background-color: #F44336;
    color: #fff;
  }
}

.mat-snack-bar-container {
  &.warning {
    background-color: #EF984B;
    color: #fff;
  }
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(173, 167, 167, 0.5);
  z-index: 999;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  content: "";
  width: 75px;
  height: 75px;
  display: inline-block;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  animation: spin 500ms infinite linear;
  -o-animation: spin 500ms infinite linear;
  -ms-animation: spin 500ms infinite linear;
  -webkit-animation: spin 500ms infinite linear;
  -moz-animation: spin 500ms infinite linear;
  border-left-color: transparent;
  border-right-color: transparent;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}